import {useEffect} from 'react';


export const useIntersector = ({eInterTarget, onIntersect, threshold = 1.0, rootMargin = "0px"}) => {

    useEffect(() => {

        let Target = eInterTarget.current
    
        if(!eInterTarget) return;

        const observer = new IntersectionObserver(onIntersect, {
            rootMargin,
            threshold

        });
        observer.observe(eInterTarget.current);
        
        return () => observer.unobserve(Target);
    }, [eInterTarget, onIntersect, threshold, rootMargin]);

}

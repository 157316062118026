import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image/withIEPolyfill'


const Footer = () => {

    const { image1, image2 } = useStaticQuery(
        graphql`
          query {
            image1: imageSharp (resolutions: {originalName: {eq: "smashwords-logo.jpg"}}) { 
                fluid (maxWidth: 250, maxHeight: 67, quality: 100, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid
                }
              
            
            }
            image2: imageSharp (resolutions: {originalName: {eq: "available_at_amazon_US_EN_logo_stacked_RGB_WHITE.png"}}) { 
                fluid (maxWidth: 145, maxHeight: 67, quality: 100, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid
                }
              
            
            }
          }
        `
      )
    return (

        <footer>
            <div className="inner">
               <div className="links">
                    <a href="https://www.facebook.com/alibipressnz" className="facebook-link">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#ffffff" viewBox="0 0 31.5 31.5">
                        <path id="Icon_awesome-facebook-square" data-name="Icon awesome-facebook-square" d="M28.125,2.25H3.375A3.375,3.375,0,0,0,0,5.625v24.75A3.375,3.375,0,0,0,3.375,33.75h9.65V23.041H8.6V18h4.43V14.158c0-4.37,2.6-6.784,6.586-6.784a26.836,26.836,0,0,1,3.9.34V12h-2.2a2.521,2.521,0,0,0-2.842,2.723V18h4.836l-.773,5.041H18.475V33.75h9.65A3.375,3.375,0,0,0,31.5,30.375V5.625A3.375,3.375,0,0,0,28.125,2.25Z" transform="translate(0 -2.25)"/>
                    </svg>
                    </a>
                    <a href="https://www.amazon.com/s?k=terry+carson&i=digital-text&crid=QZ659T7PRBTO&sprefix=terry+carson%2Cdigital-text%2C280&ref=nb_sb_noss" className="amazon-link">
                        <Img 
                            fluid={image2.fluid}
                            alt={'Available at Amazon logo'}
                            style={{
                                    width:'100%',
                                    display:'block'
                                }}              
                            />

                    </a>
               </div>
               <p className="copyright">&copy; Copyright 2008-{new Date().getFullYear()} Alibi Press. <br /></p>
               <p></p>
            
            </div>

            
        </footer>
       
    )

}

export default Footer
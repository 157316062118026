import React, { useState } from 'react'
import { Link } from "gatsby";



const Navigation = ({mobileMenu}) => {

    return ( 

        <nav> 
            
            <ul>
                <li className="first-item main-item">
                    <Link to='/' activeClassName="active"><span>Home</span></Link>
                </li>
                <li className="first-item main-item">
                    <Link to='/current-books' activeClassName="active"><span>Current Books</span></Link>
                </li>
                <li className="first-item main-item">
                    <Link to='/buy' activeClassName="active"><span>Buy</span></Link>
                </li>
                <li className="main-item">
                    <Link to='/contact-us' activeClassName="active"><span>Contact Us</span></Link>
                </li>

            </ul>
        </nav>
       
    )

}

export default Navigation